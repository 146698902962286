import { mockComplianceStatuses, mockComplianceSignatures, mockUploadAttachments } from "./customerCompliance";
import { mockDKComplianceDefinitions } from "./customerCompliance/dkCustomerCompilance";
import { mockFIComplianceDefinitions } from "./customerCompliance/fiCustomerCompliance";
import { mockNOComplianceDefinitions } from "./customerCompliance/noCustomerCompliance";
import { mockSEComplianceDefinitions } from "./customerCompliance/seCustomerCompliance";
import MockAdapter from "axios-mock-adapter";

const idRegexSegment = "[a-zA-Z0-9-]*";

export const MockCustomerComplianceApi = (mock: MockAdapter) => {
  mock.onGet("statuses").reply(200, mockComplianceStatuses);
  mock.onGet("signatures").reply(200, mockComplianceSignatures); // TODO: Implement polling support for mock
  mock.onGet("questionnaire-definitions").reply((config) => {
    if (config && config.headers) {
      const country = config.headers["x-country"];
      switch (country) {
        case "DK":
          return [200, mockDKComplianceDefinitions];
        case "FI":
          return [200, mockFIComplianceDefinitions];
        case "NO":
          return [200, mockNOComplianceDefinitions];
        case "SE":
          return [200, mockSEComplianceDefinitions];
        default:
          return [500];
      }
    }
    return [500];
  });
  mock.onPut(new RegExp(`questionnaire-answers/${idRegexSegment}`)).reply(204);
  mock.onPost(new RegExp(`questionnaire-answers/${idRegexSegment}/attachments`)).reply(200, mockUploadAttachments);
  mock.onDelete(new RegExp(`questionnaire-answers/${idRegexSegment}/attachments/${idRegexSegment}`)).reply(204);
};
