import { action, computed, makeObservable, observable } from "mobx";
import {
  IDocument,
  IGetInvoicesRequest,
  IGetPaymentsRequest,
  ILeaseAccount,
  ILeaseBaseAccount,
  IPayment,
  InvoiceDistributionFormatType,
  InvoiceStatus,
  InvoiceUpdateStatus,
  PaymentType,
} from "../libs/models/CustomerProducts";
import { Store } from "./Store";
import { LeasingApi } from "libs/api/LeasingApi";
import { SelfServiceSubmissionState } from "../libs/models/Content/Enums";
import { IMileageChangeForm } from "libs/forms/PaymentPlanChange";
import { IPowerOfAttorneyTerms } from "libs/models/PowerOfAttorney";
import { createAutoDownloadTempLink } from "../libs/utils";
import { IPowerOfAttorneyForm } from "libs/forms/PowerOfAttorneyForm";
import { IAddInsuranceForm } from "libs/forms/Insurances/AddInsuranceForm";
import { ITerminateInsuranceForm } from "libs/forms/Insurances/TerminateInsuranceForm";
import { IPromiseToPayForm } from "libs/forms/PromiseToPay/PromiseToPay";

export class LeasingStore {
  leasingApi: LeasingApi;

  rootStore: Store;

  constructor(leasingApi: LeasingApi, rootStore: Store) {
    this.leasingApi = leasingApi;
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @observable
  currentAccount: ILeaseAccount | undefined;

  @observable
  loadingAccount: boolean = false;

  @observable
  leasingCustomerProducts: ILeaseBaseAccount[] = [];

  @observable
  leasingAccountsError?: boolean;

  @observable
  leasingAccounts: ILeaseAccount[] = [];

  @computed
  get currentAccountId(): string {
    return this.rootStore.currentAccountId;
  }

  set currentAccountId(accountId: string) {
    this.rootStore.currentAccountId = accountId;
  }

  @observable
  activePayment: IPayment | undefined = undefined;

  @observable
  paidPayments: IPayment[] | undefined = undefined;

  @observable
  duePayment: IPayment | null | undefined = undefined;

  @observable
  loadingDuePayment: boolean = false;

  @observable
  loadingDuePaymentError: boolean = false;

  @observable
  overduePayments: IPayment[] | undefined = undefined;

  @observable
  loadingOverduePayments: boolean = false;

  @observable
  loadingOverduePaymentsError: boolean = false;

  @observable
  loadingPaidPayments: boolean = false;

  @observable
  loadingPaidPaymentsError: boolean = false;

  @observable
  archivedInvoices: IPayment[] | undefined = undefined;

  @observable
  loadingArchivedInvoices: boolean = false;

  @observable
  loadingArchivedInvoicesError: boolean = false;

  @observable creatingPromiseToPay: boolean = false;

  @observable
  contracts?: IDocument[];

  @observable
  loadingContracts: boolean = false;

  @observable
  newInvoiceMethod: InvoiceDistributionFormatType | undefined;

  @observable
  updatingInvoiceMethod: boolean = false;

  @observable
  invoiceUpdateStatus: InvoiceUpdateStatus = InvoiceUpdateStatus.Initial;

  @observable
  selfServiceSusbmissionState = SelfServiceSubmissionState.NotSubmitted;

  @observable
  powerOfAttorneyTerms: IPowerOfAttorneyTerms | undefined = undefined;

  @observable
  fetchingPowerOfAttorneyTerms: boolean = false;

  @observable
  fetchingPowerOfAttorneyTermsError: boolean | undefined = undefined;

  @observable
  creatingPowerOfAttorney: boolean = false;

  @observable
  creatingPowerOfAttorneyError: boolean | undefined = undefined;

  @observable
  fetchingInvoiceDocument: boolean = false;

  getLeaseAccount = async (accountId: string | undefined) => {
    if (!accountId) return;
    this.loadingAccount = true;
    const response = await this.leasingApi.getLeasingAccount(accountId);
    if (response?.ok && response.data) {
      let currentBaseAccount = this.rootStore.currentAccount;
      if (currentBaseAccount?.accountId !== accountId) {
        currentBaseAccount = this.rootStore.allCustomerProducts?.find(
          (customerProduct) => customerProduct.accountId === response.data?.leasingAccount?.accountId,
        );
        this.currentAccountId = accountId;
      }
      this.currentAccount = response.data.leasingAccount
        ? {
            ...response.data.leasingAccount,
            nickname: currentBaseAccount?.nickname,
            isAmlFrozen: currentBaseAccount?.isAmlFrozen,
          }
        : undefined;
    }
    this.loadingAccount = false;
  };

  @action
  setNicknameOptimistically = async (nickname: string) => {
    if (this.currentAccount) {
      this.currentAccount.nickname = nickname;
    }
  };

  @computed
  get hasCustomerProducts(): boolean {
    return this.leasingCustomerProducts !== undefined && this.leasingCustomerProducts.length > 0;
  }

  @computed
  get hasAccounts(): boolean {
    return this.leasingAccounts !== undefined && this.leasingAccounts.length > 0;
  }

  setCurrentAccount(accountId: string): void {
    this.currentAccountId = accountId;
  }

  getDuePayment = async (accountId: string | undefined) => {
    if (!accountId) return;
    this.loadingDuePayment = true;
    this.loadingDuePaymentError = false;
    const paymentData: IGetPaymentsRequest = { accountId, paymentType: PaymentType.Due };
    const response = await this.leasingApi.getPayments(paymentData);
    if (response?.ok && response.data) {
      this.duePayment = response.data.payments[0];
    } else if (response?.status === 404) {
      this.duePayment = null;
    } else {
      this.loadingDuePaymentError = true;
    }
    this.loadingDuePayment = false;
  };

  getOverduePayments = async (accountId: string | undefined) => {
    if (!accountId) return;
    this.loadingOverduePayments = true;
    this.loadingOverduePaymentsError = false;
    const paymentData: IGetPaymentsRequest = { accountId, paymentType: PaymentType.Overdue };
    const response = await this.leasingApi.getPayments(paymentData);
    if (response?.ok && response.data) {
      this.overduePayments = response.data.payments;
    } else if (response?.status === 404) {
      this.overduePayments = [];
    } else {
      this.loadingOverduePaymentsError = true;
    }
    this.loadingOverduePayments = false;
  };

  getPaidPayments = async (accountId: string) => {
    this.loadingPaidPayments = true;
    this.loadingPaidPaymentsError = false;
    const paymentData: IGetPaymentsRequest = { accountId, paymentType: PaymentType.Paid };
    const response = await this.leasingApi.getPayments(paymentData);
    if (response?.ok && response.data) {
      this.paidPayments = response.data.payments;
    } else if (response?.status === 404) {
      this.paidPayments = [];
    } else {
      this.loadingPaidPaymentsError = true;
    }
    this.loadingPaidPayments = false;
  };

  setActivePayment(payment: IPayment): void {
    this.activePayment = payment;
  }

  getArchivedInvoices = async (accountId: string) => {
    this.loadingArchivedInvoices = true;
    this.loadingArchivedInvoicesError = false;
    const invoicesData: IGetInvoicesRequest = { accountId, invoiceStatus: InvoiceStatus.Paid };
    const response = await this.leasingApi.getInvoices(invoicesData);
    if (response?.ok && response.data) {
      this.archivedInvoices = response.data.invoices;
    } else if (response?.status === 404) {
      this.paidPayments = [];
    } else {
      this.loadingArchivedInvoicesError = true;
    }
    this.loadingArchivedInvoices = false;
  };

  submitPromiseToPay = async (accountId: string, data: IPromiseToPayForm) => {
    this.creatingPromiseToPay = true;
    const invoiceInfo = this.currentAccount?.canPromiseToPayInvoiceInformation;

    if (!invoiceInfo?.invoiceNumber) {
      return;
    }

    const response = await this.leasingApi.promiseToPayInvoice(accountId, invoiceInfo.invoiceNumber, data);
    if (response?.ok) {
      this.setSelfServiceSubmissionSuccess();
    } else {
      this.selfServiceSusbmissionState = SelfServiceSubmissionState.Error;
    }
    this.creatingPromiseToPay = false;
  };

  getContracts = async () => {
    this.contracts = [];
    this.loadingContracts = true;
    const response = await this.leasingApi.getContracts(this.currentAccountId);
    if (response?.ok && response.data) {
      this.contracts = response.data;
    }
    this.loadingContracts = false;
  };

  changeInvoiceMethod = async () => {
    if (!this.newInvoiceMethod || !this.currentAccountId || !this.currentAccount) return;
    this.updatingInvoiceMethod = true;
    const response = await this.leasingApi.changeInvoiceMethod(this.currentAccountId, this.newInvoiceMethod);
    if (response?.ok) {
      this.currentAccount.invoiceDistributionFormat = this.newInvoiceMethod;
      this.invoiceUpdateStatus = InvoiceUpdateStatus.Success;
    } else {
      this.invoiceUpdateStatus = InvoiceUpdateStatus.Failed;
    }
    this.updatingInvoiceMethod = false;
  };

  getPowerOfAttorneyTerms = async (accountId: string | undefined) => {
    if (!accountId) return;
    this.fetchingPowerOfAttorneyTerms = true;
    const response = await this.leasingApi.getPowerOfAttorneyTerms(accountId);
    this.fetchingPowerOfAttorneyTerms = false;
    if (response?.ok) {
      this.powerOfAttorneyTerms = response.data;
      return;
    }
    this.fetchingPowerOfAttorneyTermsError = true;
  };

  createPowerOfAttorney = async (accountId: string, data: IPowerOfAttorneyForm) => {
    this.creatingPowerOfAttorney = true;
    const response = await this.leasingApi.createPowerOfAttorney(accountId, data);
    this.creatingPowerOfAttorney = false;
    if (response?.ok) {
      this.creatingPowerOfAttorneyError = false;
      return;
    }
    this.creatingPowerOfAttorneyError = true;
  };

  @action
  setUpdatedInvoiceMethod = (newInvoiceMethod: InvoiceDistributionFormatType) => {
    this.newInvoiceMethod = newInvoiceMethod;
  };

  @action
  getInvoiceDocument = async (invoiceId?: string) => {
    if (!invoiceId) return;
    this.fetchingInvoiceDocument = true;
    const response = await this.leasingApi.getInvoiceDocument(this.currentAccountId, invoiceId);
    if (response?.ok && response.data) {
      if (response.data) {
        const objectUrl = URL.createObjectURL(response.data);
        createAutoDownloadTempLink(`${invoiceId}.pdf`, objectUrl, true, true);
      }
    }
    this.fetchingInvoiceDocument = false;
  };

  @action
  resetChangeInvoiceMethod = () => {
    this.newInvoiceMethod = undefined;
    this.invoiceUpdateStatus = InvoiceUpdateStatus.Initial;
    this.updatingInvoiceMethod = false;
  };

  @action
  requestMileageChange = async (data: IMileageChangeForm) => {
    if (!this.currentAccount?.accountId || !this.currentAccount.canRequestMileageChange) {
      this.selfServiceSusbmissionState = SelfServiceSubmissionState.Error;
      return;
    }

    const response = await this.leasingApi.requestMileageChange(this.currentAccount.accountId, data);
    if (response?.ok) this.setSelfServiceSubmissionSuccess();
    else this.selfServiceSusbmissionState = SelfServiceSubmissionState.Error;
  };

  @action
  addInsurance = async (data: IAddInsuranceForm) => {
    if (!this.currentAccount?.accountId || !this.currentAccount.canRequestInsurance) {
      this.selfServiceSusbmissionState = SelfServiceSubmissionState.Error;
      return;
    }

    const response = await this.leasingApi.addInsurance(this.currentAccount.accountId, data);
    if (response?.ok) this.setSelfServiceSubmissionSuccess();
    else this.selfServiceSusbmissionState = SelfServiceSubmissionState.Error;
  };

  @action
  terminateInsurance = async (data: ITerminateInsuranceForm) => {
    if (
      !this.currentAccount?.accountId ||
      !this.currentAccount.insurances ||
      this.currentAccount.insurances.findIndex((insurance) => !!insurance.canTerminate) === -1
    ) {
      this.selfServiceSusbmissionState = SelfServiceSubmissionState.Error;
      return;
    }

    const response = await this.leasingApi.terminateInsurance(this.currentAccount.accountId, data);
    if (response?.ok) this.setSelfServiceSubmissionSuccess();
    else this.selfServiceSusbmissionState = SelfServiceSubmissionState.Error;
  };

  @action
  setSelfServiceSubmissionSuccess = () => {
    this.selfServiceSusbmissionState = SelfServiceSubmissionState.Success;
    this.rootStore.resetCurrentAccountTemplateCache();
  };

  @action
  resetLeasingPayments = () => {
    this.activePayment = undefined;
    this.archivedInvoices = undefined;
    this.loadingArchivedInvoices = false;
    this.loadingArchivedInvoicesError = false;
    this.duePayment = undefined;
    this.loadingDuePayment = false;
    this.loadingDuePaymentError = false;
    this.overduePayments = undefined;
    this.loadingOverduePayments = false;
    this.loadingOverduePaymentsError = false;
    this.paidPayments = undefined;
    this.loadingPaidPayments = false;
    this.loadingPaidPaymentsError = false;
  };

  @action
  resetContracts = () => {
    this.contracts = undefined;
    this.loadingContracts = false;
  };

  @action
  resetPromiseToPayStates = () => {
    this.creatingPromiseToPay = false;
    this.selfServiceSusbmissionState = SelfServiceSubmissionState.NotSubmitted;
  };

  @action
  resetCurrentAccount = () => {
    this.currentAccount = undefined;
    this.currentAccountId = "";
  };

  @action
  resetSelfServiceState = () => {
    this.selfServiceSusbmissionState = SelfServiceSubmissionState.NotSubmitted;
  };

  @action
  resetPowerOfAttorneyState = () => {
    this.powerOfAttorneyTerms = undefined;
    this.fetchingPowerOfAttorneyTerms = false;
    this.fetchingPowerOfAttorneyTermsError = undefined;
    this.creatingPowerOfAttorney = false;
    this.creatingPowerOfAttorneyError = undefined;
  };

  @action
  resetStore = () => {
    this.currentAccount = undefined;
    this.currentAccountId = "";
    this.leasingAccounts = [];
    this.leasingCustomerProducts = [];
    this.loadingAccount = false;
    this.resetContracts();
    this.resetLeasingPayments();
    this.resetPromiseToPayStates();
    this.resetChangeInvoiceMethod();
    this.resetSelfServiceState();
    this.resetPowerOfAttorneyState();
  };
}
