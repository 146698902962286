export enum ContentType {
  Page = "Page",
  Block = "Block",
  AccordionBlock = "AccordionBlock",
  DividerBlock = "DividerBlock",
  BasicTextBlock = "BasicTextBlock",
  BasicInfoBlock = "BasicInfoBlock",
}

export enum Locale {
  en = "en_GB",
  sv = "sv-SE",
  fi = "fi-FI",
  no = "nb-NO",
  dk = "da-DK",
}

export enum CountryCode {
  EN = "EN",
  SE = "SE",
  FI = "FI",
  NO = "NO",
  DK = "DK",
}

export enum Lang {
  en = "en",
  sv = "sv",
  fi = "fi",
  no = "no",
  dk = "dk",
}

export enum Currency {
  SEK = "SEK",
  EUR = "EUR",
  NOK = "NOK",
  DKK = "DKK",
}

export enum Environment {
  SIT = "SIT",
  PreProduction = "PreProduction",
  Production = "Production",
}

export enum UrlType {
  MailTo = "mailto",
  Tel = "tel",
  File = "file",
  PDFFile = "pdfFile",
  Internal = "internal",
  External = "external",
}

export enum FileType {
  Url = "url",
  Base64 = "base64",
}

export enum PageType {
  MessageInbox = "MessageInboxPage",
  NewMessage = "NewMessagePage",
  CardSettingsPage = "CardSettingsPage",
  StandardPage = "StandardPage",
  HomePage = "HomePage",
  NotFoundPage = "404Page",
  CardTransitPage = "CardTransitPage",
  DepositTransitPage = "DepositTransitPage",
  LeasingTransitPage = "LeasingTransitPage",
  PrivateLoanTransitPage = "PrivateLoanTransitPage",
  SecuredLoanTransitPage = "SecuredLoanTransitPage",
  SalesFinanceTransitPage = "SalesFinanceTransitPage",
  TermsPage = "TermsPage",
  CardTemplatePage = "CardTemplatePage",
  DepositTemplatePage = "DepositTemplatePage",
  LeasingTemplatePage = "LeasingTemplatePage",
  LoanTemplatePage = "LoanTemplatePage",
  PrivateLoanTemplatePage = "PrivateLoanTemplatePage",
  SecuredLoanTemplatePage = "SecuredLoanTemplatePage",
  SalesFinanceTemplatePage = "SalesFinanceTemplatePage",
  ContainerPage = "ContainerPage",
  ClosingOfDepositAccountPage = "ClosingOfDepositAccountPage",
  CustomerCompliancePage = "CustomerCompliancePage",
  LoanTerminationsPage = "LoanTerminationsPage",
  UpdateDueDayPage = "UpdateDueDayPage",
  PowerOfAttorneyPage = "PowerOfAttorneyPage",
  PromiseToPayPage = "PromiseToPayPage",
  MileageChangePage = "MileageChangePage",
  ReduceInstallmentsPage = "ReduceInstallmentsPage",
  IncreaseInstallmentsPage = "IncreaseInstallmentsPage",
  ExtendResidualAmountPaymentPage = "ExtendResidualAmountPaymentPage",
  AddInstallmentPage = "AddInstallmentPage",
  AddInsurancePage = "AddInsurancePage",
  TerminateInsurancePage = "TerminateInsurancePage",
  PaymentHolidayPage = "PaymentHolidayPage",
  IncreaseLoanPage = "IncreaseLoanPage",
}

export enum StandardPageType {
  TransferPage = "TransferPage",
  Default = "Default",
}

export enum CardActionBlockTypes {
  AccordionActivateCardBlock = "AccordionActivateCardBlock",
  AccordionBlockCardBlock = "AccordionBlockCardBlock",
  AccordionOrderCardBlock = "AccordionOrderCardBlock",
}

export enum LoanActionBlockTypes {
  AccordionTerminationAmountBlock = "AccordionTerminationAmountBlock",
}

export enum SelfServiceButtonType {
  Link = "link",
  LinkAccountQuery = "link-account-query",
  Modal = "modal",
  ClosingDepositLink = "closing-deposit-link",
  LoanTerminationsLink = "loan-terminations-link",
  UpdateDueDayLink = "update-due-day-link",
  PowerOfAttorneyLink = "power-of-attorney-link",
  PromiseToPayLink = "promise-to-pay-link",
  NemPayment = "nem-payment",
  DepositSelfServiceLink = "deposit-self-service-link",
  CreditSelfServiceLink = "credit-self-service-link",
  LoanSelfServiceLink = "loan-self-service-link",
  LeasingSelfServiceLink = "leasing-self-service-link",
}

export enum TransferStatus {
  QuotaExceeded = "QuotaExceeded",
  NoFunding = "NoFunding",
  ValidationFailed = "ValidationFailed",
  Succeeded = "Succeeded",
  Enqueued = "Enqueued",
  Failed = "Failed",
  PendingTransfer = "PendingTransfer",
  SystemClosed = "SystemClosed",
  UnverifiedAccountPolicyFail = "UnverifiedAccountPolicyFail",
  Pending = "Pending",
  IllegalForeignAccount = "IllegalForeignAccount",
}

export enum TransferStatusType {
  Positive = 0,
  Negative = 1,
}

export enum Colors {
  Primary = "primary",
  BackgroundPrimary = "backgroundPrimary",
  Red = "red",
  Mercury = "mercury",
  Boulder = "boulder",
  Black = "black",
  White = "white",
  BorderGrey = "borderGrey",
  Botticelli = "botticelli",
  Tundora = "tundora",
  Sinbad = "sinbad",
  BlackSqueeze = "blackSqueeze",
  Jellybean = "jellybean",
  MediumSky = "mediumSky",
  DarkSky = "darkSky",
  LightError = "lightError",
  LightWarning = "lightWarning",
  MilkPunch = "milkPunch",
  Alto = "alto",
  Silver = "silver",
  Turquoise = "turquoise",
  SurfieGreen = "surfieGreen",
  DoveGrey = "doveGrey",
  AccesibleSky = "accesibleSky",
  LimeGreen = "limeGreen",
  Sunglow = "sunglow",
  LightSuccess = "lightSuccess",
  CatskillWhite = "catskillWhite",
}

export enum MessageSender {
  CaseHandler = "Case Handler",
  Santander = "Santander",
}

export enum Direction {
  Sent = "Sent",
  Received = "Received",
}

export enum AccountType {
  Credit = "Credit",
  PrivateLoan = "PrivateLoan",
  SecuredLoan = "SecuredLoan",
  SalesFinance = "SalesFinance",
  Deposit = "Deposit",
  PrivateLeasing = "PrivateLeasing",
}

export enum LoanType {
  PrivateLoan = "PrivateLoan",
  SecuredLoan = "SecuredLoan",
  SalesFinance = "SalesFinance",
}

export enum Product {
  NoProduct = "NoProduct",
  Credit = "Credit",
  ConsumerLoan = "ConsumerLoan",
  CarLoan = "CarLoan",
  Deposit = "Deposit",
  PrivateLeasing = "PrivateLeasing",
  SalesFinance = "SalesFinance",
}

export enum Limits {
  invoice = 8,
  Letters = 8,
}

export enum AccountRedemptionStatus {
  Unknown = "Unknown",
  NoRedemptionInfo = "NoRedemptionInfo",
  Redeemable = "Redeemable",
  RedemptionSuccessful = "RedemptionSuccessful",
  RedemptionFailed = "RedemptionFailed",
}

export enum LoanTerminationsStep {
  CalcStep = "CalcStep",
  ConsentStep = "ConsentStep",
  PaymentStep = "PaymentStep",
}

export enum SvgLogoType {
  SantanderFlameRed = "SantanderFlameRed",
  SantanderLogoRed = "SantanderLogoRed",
  SantanderLogoWhite = "SantanderLogoWhite",
  ChevronRight = "ChevronRight",
  ChevronLeft = "ChevrpnLeft",
  ChevronDown = "ChevronDown",
  ChevronRed = "ChevronRed",
  BankId = "BankId",
  Card = "Card",
  Loan = "Loan",
  Deposit = "Deposit",
  Leasing = "Leasing",
  CardTemplateLogo = "CardTemplateLogo",
  DepositTemplateLogo = "DepositTemplateLogo",
  HomeIcon = "HomeIcon",
  TransferIcon = "TransferIcon",
  MessagesIcon = "MessagesIcon",
  OpenMessageIcon = "OpenMessageIcon",
  HamburgerIcon = "HamburgerIcon",
  CloseIcon = "CloseIcon",
  PlusIcon = "PlusIcon",
  MinusIcon = "MinusIcon",
  WriteIcon = "WriteIcon",
  SearchIcon = "SearchIcon",
  SearchIconInput = "SearchIconInput",
  ArchiveIcon = "ArchiveIcon",
  BookmarkRedIcon = "BookmarkRedIcon",
  UndoIcon = "UndoIcon",
  PdfIcon = "PdfIcon",
  SantanderLogoIcon = "SantanderLogoIcon",
  SmallSantanderLogo = "SmallSantanderLogo",
  Error = "ErrorIcon",
  Information = "InformationIcon",
  Important = "ImportantIcon",
  DeleteIcon = "DeleteIcon",
  EditIcon = "EditIcon",
  ExpandPlusIcon = "ExpandPlusIcon",
  ExpandMinusIcon = "ExpandMinusIcon",
  InfoPopover = "InfoPopover",
  Calendar = "CalendarIcon",
  Checkmark = "Checkmark",
  CardChip = "CardChip",
  Copy = "Copy",
  Check = "Check",
  MoneyTransfer = "MoneyTransfer",
  MoneyHand = "MoneyHand",
  QuestionMark = "QuestionMark",
  ArrowDown = "ArrowDown",
  Alert = "Alert",
  Phone = "Phone",
  Mail = "Mail",
}

export enum AccordionType {
  PageView = "PageView",
  AccordionView = "AccordionView",
}

export enum ModalBlock {
  NewTransferRecieverModalBlock = "NewTransferRecieverModalBlock",
  TransactionModalBlock = "TransactionModalBlock",
  DateRangeModalBlock = "DateRangeModalBlock",
  UpdateContactInfoModalBlock = "UpdateContactInfoModalBlock",
}

export enum AccordionViewBlock {
  SelectMenuList = "SelectMenuList",
}

export enum FormOperation {
  New = "New",
  Update = "Update",
}

export enum TransactionStatus {
  Reserved = "Reserved",
  Outgoing = "Outgoing",
}

export enum DepositTypeName {
  Opsparing6 = "Opsparing 6",
  Opsparing12 = "Opsparing 12",
  Opsparing24 = "Opsparing 24",
}

export enum DepositType {
  Opsparing6 = "Opsparing6",
  Opsparing12 = "Opsparing12",
  Opsparing24 = "Opsparing24",
  HoejrenteFri = "HoejrenteFri",
  HoejrenteFriAndPersonale = "HoejrenteFriAndPersonale",
  HoejrentePlus = "HoejrentePlus",
  HoejrenteFriNy = "HoejrenteFriNy",
  Other = "Other",
}

export enum ComplianceLanguage {
  ENG = "ENG",
  SWE = "SWE",
  NOR = "NOR",
  DAN = "DAN",
  FIN = "FIN",
}

export enum PaymentDataType {
  AccountNumber = "account-number",
  AccountName = "account-name",
  AdministrationFee = "administration-fee",
  Balance = "balance",
  CustomerNumber = "customer-number",
  DueDate = "due-date",
  FeesAndInsurance = "fees-and-insurance",
  InterestRate = "interest-rate",
  InterestAmount = "interest-amount",
  Installment = "installment",
  InsuranceFee = "insurance-fee",
  InvoiceDate = "invoice-date",
  InvoiceNumber = "invoice-number",
  InvoicePeriod = "invoice-period",
  InvoicePeriodStartDate = "invoice-period-start-date",
  RemainingDebt = "remaining-debt",
  TotalAmount = "total-amount",
}

export enum InvoiceReferenceLineType {
  BankAccount = "bank-account",
  BIC = "bic",
  InvoiceReference = "invoice-reference",
  ReceiverName = "receiver-name",
  VirtualBarcode = "virtual-barcode",
}

export enum ConversationEmptyState {
  ConversationNotFound = "ConversationNotFound",
  ReplyMessageSuccess = "ReplyMessageSuccess",
  ReplyMessageFailed = "ReplyMessageFailed",
  CreateConversationSuccess = "CreateConversationSuccess",
  CreateConversationFailed = "CreateConversationFailed",
}

export enum AllowedAttachmentAmountSetting {
  None = "None",
  Single = "Single",
  Many = "Many",
}

export enum AccordionName {
  AccordionOverduePaymentsBlock = "AccordionOverduePaymentsBlock",
  AccordionActiveInvoiceBlock = "AccordionActiveInvoiceBlock",
  // Add more accordions when necessary (add name of file/component)
}

export enum InsuranceCompany {
  OP = "OP",
  IF = "IF",
  Lahitapiola = "Lahitapiola",
  Fennia = "Fennia",
  Turva = "Turva",
  Pohjantahti = "Pohjantahti",
  Folksam = "Folksam",
  Pop = "Pop",
}

export enum ExportCountry {
  Albania = "Albania",
  Andorra = "Andorra",
  Armenia = "Armenia",
  Austria = "Austria",
  Azerbaijan = "Azerbaijan",
  Belarus = "Belarus",
  Belgium = "Belgium",
  BosniaAndHerzegovina = "Bosnia and Herzegovina",
  Bulgaria = "Bulgaria",
  Croatia = "Croatia",
  Cyprus = "Cyprus",
  CzechRepublic = "Czech Republic",
  Denmark = "Denmark",
  Estonia = "Estonia",
  Finland = "Finland",
  France = "France",
  Georgia = "Georgia",
  Germany = "Germany",
  Greece = "Greece",
  Hungary = "Hungary",
  Iceland = "Iceland",
  Ireland = "Ireland",
  Italy = "Italy",
  Kazakhstan = "Kazakhstan",
  Kosovo = "Kosovo",
  Latvia = "Latvia",
  Liechtenstein = "Liechtenstein",
  Lithuania = "Lithuania",
  Luxembourg = "Luxembourg",
  NorthMacedonia = "North Macedonia",
  Malta = "Malta",
  Moldova = "Moldova",
  Monaco = "Monaco",
  Montenegro = "Montenegro",
  Netherlands = "Netherlands",
  Norway = "Norway",
  Poland = "Poland",
  Portugal = "Portugal",
  Romania = "Romania",
  Russia = "Russia",
  SanMarino = "SanMarino",
  Serbia = "Serbia",
  Slovakia = "Slovakia",
  Slovenia = "Slovenia",
  Spain = "Spain",
  Sweden = "Sweden",
  Switzerland = "Switzerland",
  Turkey = "Turkey",
  Ukraine = "Ukraine",
  UnitedKingdom = "United Kingdom",
  VaticanCity = "Vatican City",
  Iran = "Iran",
  Israel = "Israel",
  Morocco = "Morocco",
  Tunis = "Tunis",
}

export enum DepositAccordionAccountType {
  Active = "Active Deposit",
  Closed = "Closed Deposit",
}

export enum CreditAccordionAccountType {
  Active = "Active Credit",
  Closed = "Closed Credit",
}

export enum HeaderMenuItem {
  Messages = "messages",
  CustomerProfile = "customer-profile",
  Logout = "logout",
}

export enum SelfServiceSubmissionState {
  NotSubmitted = "NotSubmitted",
  Success = "Success",
  Error = "Error",
}

export enum IncreaseInstallmentsType {
  Balance = "Balance",
  BalloonPayment = "BalloonPayment",
}

export enum AddInstallmentType {
  Balance = "Balance",
  NextPayment = "NextPayment",
  BalloonPayment = "BalloonPayment",
}

export enum TerminateInsuranceReason {
  NoLongerNeeded = "NoLongerNeeded",
  NotEligible = "NotEligible",
  NegativeDecision = "NegativeDecision",
  TooExpensive = "TooExpensive",
  NotSuitable = "NotSuitable",
  Other = "Other",
}

export enum PaymentHolidayConsecutivePeriodConditionType {
  ConsecutiveMonthsAllowed = "ConsecutiveMonthsAllowed",
  ConsecutiveMonthsForbidden = "ConsecutiveMonthsForbidden",
  NonGapingChronologicalMonthsRequired = "NonGapingChronologicalMonthsRequired",
}

export enum InvoiceType {
  ActiveInvoice = "ActiveInvoice",
  OverdueInvoice = "OverdueInvoice",
  InvoiceArchive = "InvoiceArchive",
}

export enum GdprInformationType {
  Application = "Application",
  Contract = "Contract",
  Customer = "Customer",
}

export enum ApplicationStatus {
  New = "new",
  Pending = "pending",
  Scoring = "scoring",
  Scored = "scored",
  Disbursement = "disbursement",
  Completed = "completed",
  Rejected = "rejected",
  Cancelled = "cancelled",
}
