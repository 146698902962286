/* eslint-disable max-len */
import { ComplianceFieldType, IComplianceDefinition } from "../../../models/CustomerCompliance/Compliance";


export const mockFIComplianceDefinitions: IComplianceDefinition[] = [
  {
    answerId: "MTAwMjY3N18w",
    title: "Autolaina- ja leasing-tuotelomake",
    questions: [
      {
        id: "YjlmMmNhNTMtMjhiOC00YTExLTg5YjQtZTMxNmU4NWQ4OWI2",
        type: ComplianceFieldType.h3,
        editable: false,
        answers: undefined,
        questionText: "Autolaina- ja leasing-tuotelomake",
        options: undefined,
        children: undefined,
      },
      {
        id: "OWUzOWY4OWEtZjVkNi00YmU0LWEwNTItYjQ5NTdlZDcxM2Qz",
        type: ComplianceFieldType.h4,
        editable: false,
        answers: undefined,
        questionText:
          "Yritystietojesi päivittämisen yhteydessä olemme myös velvollisia keräämään käyttäjätietoja tuotteista, joita yrityksellä on Santander Consumer Bank:n kanssa.",
        options: undefined,
        children: undefined,
      },
      {
        id: "OWNhZDAxNGItOWMxMi00NjE1LTlkM2ItZTNkNWUyYTk3ODM2",
        type: ComplianceFieldType.h4,
        editable: false,
        answers: undefined,
        questionText:
          "Suomen rahanpesulain mukaan meillä on pankkina velvollisuus kerätä asiakastietoja jatkuvasti. Tämä on tärkeä osa talousrikollisuuden, rahanpesun ja terrorismin rahoittamisen vastaista työtä, ja sen tarkoituksena on suojata sekä yritystäsi asiakkaana että pankkia.",
        options: undefined,
        children: undefined,
      },
      {
        id: "YzkzNGE4YjktODEzOC00NDVkLTlkMmItOTRjZDQ3YmY0Yjli",
        type: ComplianceFieldType.h5,
        editable: false,
        answers: undefined,
        questionText: "Kuinka täyttää lomake:",
        options: undefined,
        children: undefined,
      },
      {
        id: "YTNkMGExYzYtN2NkYi00Njc0LWJkMTEtODkyODQ2NWMzZmZh",
        type: ComplianceFieldType.h4,
        editable: false,
        answers: undefined,
        questionText: "Täytä kysymykset. Kun olet vastannut kaikkiin lomakkeen osiin, voit napsauttaa «Lähetä».",
        options: undefined,
        children: undefined,
      },
    ],
  },
  {
    answerId: "MTAwMjY3N18x",
    title: "Autolaina- ja leasing-tuotelomake",
    questions: [
      {
        id: "",
        type: ComplianceFieldType.Conditional,
        editable: false,
        answers: undefined,
        questionText: undefined,
        options: undefined,
        children: [
          {
            id: "MTAwMjY3N18xX0Z1bmRfU291cmNlX0Zvcl9SZXBheW1lbnRfQXV0b19Mb2FuX0xlYXNlX09SRw==",
            type: ComplianceFieldType.DropDownList,
            editable: false,
            answers: [
              {
                id: "Retained_Profit",
                text: "Yhtiön tavanomainen toiminta",
              },
            ],
            questionText:
              "Ilmoittakaa pääasiassa lainan/vuokrasopimuksen takaisinmaksuun tarvittavien varojen lähde",
            questionHelpText: "Pääasiallinen rahoituslähde",
            options: [
              {
                id: "Retained_Profit",
                text: "Yhtiön tavanomainen toiminta",
              },
              {
                id: "Accumulated_Equity_Capital",
                text: "Yhtiön kertynyt oma pääoma",
              },
              {
                id: "Debt_Or_Borrowed_Capital",
                text: "Velka / velkapääoma",
              },
              {
                id: "Other",
                text: "Toinen",
              },
            ],
            children: undefined,
          },
          {
            id: "TWpjd056ZzFNMTh3X19VSFZ5Y0c5elpWOVBabDlCWTJOdmRXNTBYMFJsY0c5emFYVENwTUtrd3FRd3dxVENwRlJ5ZFdYQ3BNS2ssdsadsafdasf=",
            type: ComplianceFieldType.FileUploader,
            editable: true,
            answers: [],
            questionText: "Lataa tiedostoja",
            questionHelpText:
              "Lataa tiedostosi tähän, tuetut tiedostotyypit ovat ",
            options: [],
            conditions: [],
            children: undefined,
          },
          {
            id: "NDc1YTg1ZGUtYTc4MS00M2QxLWFjNjEtMWE1ZWE1NWJkNmNm",
            type: ComplianceFieldType.paragraph,
            editable: false,
            answers: undefined,
            questionText: "Jos valitsit vaihtoehdon «muu», anna lisätietoja alla olevassa osiossa",
            options: undefined,
            children: undefined,
          },
          {
            id: "MTAwMjY3N18xX0Z1bmRfU291cmNlX0Zvcl9SZXBheW1lbnRfSWZfT3RoZXJfQXV0b19MX0xfT1JH",
            type: ComplianceFieldType.TextArea,
            editable: true,
            answers: [
              {
                id: "Fund_Source_For_Repayment_If_Other_Auto_L_L_ORG",
                text: "Takaisinmaksun rahoituslähde",
              },
            ],
            questionText:
              "Ilmoittakaa pääasiassa varojen lähde autolainan/leasingsopimuksen takaisinmaksuun",
            questionHelpText: "Tämä on joitain laajennettuja tietoja",
            options: undefined,
            children: undefined,
          },
        ],
      },
    ],
  },
  {
    answerId: "MTAwMjY3N18y",
    title: "Asiakkaan lomake",
    questions: [
      {
        id: "MmFjYjkxZjMtOTcwNi00MTQ0LWE0MGEtYTVlYWIwMDgyY2Qw",
        type: ComplianceFieldType.h5,
        editable: false,
        answers: undefined,
        questionText:
          "Rastittamalla alla olevan ruudun vahvistat, että kaikki tässä lomakkeessa annetut tiedot ovat oikein ja että olet vastannut kaikkiin tämän tuotelomakkeen kysymyksiin.u",
        options: undefined,
        children: undefined,
      },
      {
        id: "MTAwMjY3N18yX0RlY2xhcmF0aW9u",
        type: ComplianceFieldType.RadioButtonsYesNo,
        editable: true,
        answers: [
          {
            id: "True",
            text: "Joo",
          },
        ],
        questionText: "Vahvistan, että antamani tiedot ovat oikein",
        questionHelpText: "Vahvistan",
        options: [
          {
            id: "True",
            text: "Joo",
          },
          {
            id: "False",
            text: "Ei",
          },
        ],
        children: undefined,
      },
      {
        id: "N2EwNjUzODUtZGM4Yy00N2MxLWFhNTItYzJiNjJjMDJkZmUz",
        type: ComplianceFieldType.paragraph,
        editable: false,
        answers: undefined,
        questionText: "Jos vastaus on «ei», ilmoittakaa syy tähän alla olevassa osiossa",
        options: undefined,
        children: undefined,
      },
      {
        id: "MTAwMjY3N18yX1BsZWFzZV9zcGVjaWZ5",
        type: ComplianceFieldType.TextArea,
        editable: true,
        answers: [],
        questionText: "Määritä",
        options: undefined,
        children: undefined,
      },
      {
        id: "MjllOWJiNjYtYWYwZi00NGMyLTgyN2MtYjExNjVlNDY2NDA3",
        type: ComplianceFieldType.paragraph,
        editable: false,
        answers: undefined,
        questionText:
          "Sinuun voidaan ottaa yhteyttä myöhemmin, jos tarvitsemme lisätietoja yrityksestäsi/organisaatiostasi.                             nKiitämme yhteistyöstäsi, jonka avulla voimme pitää yrityksesi/organisaatiosi asiakasprofiilin ajan tasalla!",
        options: undefined,
        children: undefined,
      },
    ],
  },
];
