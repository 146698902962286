import React from "react";
import { observer } from "mobx-react";
import styles from "./InfoPopover.scss";
import informationIcon from "~assets/information-icon.svg";
import questionMarkIcon from "~assets/questionmark.svg";
import { Popover } from "../Popover/Popover";

export interface IInfoPopoverProps {
  label?: string;
  popoverTitle?: string;
  className?: string;
  content?: string;
  icon?: "info" | "question";
}

export const InfoPopover = observer(({ label, popoverTitle, content, className, icon = "info" }: IInfoPopoverProps) => {
  const popoverRef = React.useRef<HTMLButtonElement>(null);
  const dialogRef = React.useRef<HTMLDialogElement>(null);

  const [open, setOpen] = React.useState(false);
  const classes = [styles.wrapper];

  if (className) {
    classes.push(className);
  }

  return (
    <>
      <button
        type="button"
        ref={popoverRef}
        className={classes.join(" ")}
        onClick={() => {
          setOpen(true);
        }}
      >
        <img src={icon === "info" ? informationIcon : questionMarkIcon} alt="info-icon" />
        {label && <span>{label}</span>}
      </button>
      <Popover
        open={open}
        title={popoverTitle || ""}
        content={content || ""}
        buttonRef={popoverRef}
        xOffset={16}
        yOffset={20}
        dialogRef={dialogRef}
        handleClose={() => {
          setOpen(false);
        }}
      />
    </>
  );
});
