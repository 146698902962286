import React from "react";
import { observer } from "mobx-react";
import styles from "./Popover.scss";
import { useStores } from "netbank-shared/src/hooks";
import close from "~assets/cancel-black-bold.svg";
import { tx } from "netbank-shared/src/libs/i18n";

interface IPopoverProps {
  title: string;
  content: string;
  handleClose: () => void;
  open: boolean;
  buttonRef: React.RefObject<HTMLButtonElement>;
  dialogRef: React.RefObject<HTMLDialogElement>;
  xOffset?: number;
  yOffset?: number;
}

export const Popover = observer(
  ({ title, content, buttonRef, dialogRef, xOffset = 0, yOffset = 0, open, handleClose }: IPopoverProps) => {
    const { uiStore } = useStores();
    const classes = [styles.wrapper];
    const [position, setPosition] = React.useState({ top: 0, left: 0 });
    const [isFlipped, setIsFlipped] = React.useState(false);
    const [dialogVisibility, setDialogVisibility] = React.useState<"hidden" | "visible">("hidden");
    const [dialogOpacity, setDialogOpacity] = React.useState(0);
    const buttonElement = buttonRef?.current;

    const desktopWidth = 360;
    const elementRect = buttonElement?.getBoundingClientRect();

    const calculatePopoverPosition = () => {
      let top = 0;

      if (buttonElement && elementRect && dialogRef.current !== null) {
        const paddingTop = 20;
        top =
          window.scrollY -
          (dialogRef?.current?.offsetHeight ?? 0) +
          elementRect.top -
          (buttonElement.offsetHeight ?? 0) -
          paddingTop +
          yOffset;

        const shouldFlip = elementRect.top < dialogRef?.current?.offsetHeight;

        if (shouldFlip) {
          setIsFlipped(true);
          top = window.scrollY + elementRect.top + (buttonElement.offsetHeight ?? 0) + paddingTop;
        } else {
          setIsFlipped(false);
        }
      }

      const left = elementRect?.left || 0;
      const popoverPosition = { top, left: 8 };

      if (!uiStore.isMobile && buttonElement) {
        popoverPosition.left = left + (buttonElement.offsetWidth || 0) - desktopWidth + xOffset;
      }

      setPosition(popoverPosition);
    };

    const closeModal = () => {
      setDialogVisibility("hidden");
      dialogRef.current?.close();
    };

    React.useEffect(() => {
      if (open) {
        dialogRef.current?.showModal();
        dialogRef.current?.classList.add("showPopoverBackdrop");
        calculatePopoverPosition();
        // Hidden until position is set, as it otherwise can cause flashing. Both opacity and visiblity needed,
        // visibility to completely hide dialog and avoid a scroll-to-top issue, and opacity to hide dialog while
        // keeping backdrop visible for the fade animation.
        setDialogOpacity(1);
        setDialogVisibility("visible");
      } else if (dialogRef.current?.open) {
        dialogRef.current?.addEventListener("transitionend", closeModal, { once: true });
        dialogRef.current?.classList.remove("showPopoverBackdrop");
        setDialogOpacity(0);
      }
    }, [open]);

    const handleClickOutside = (e: MouseEvent) => {
      const rect = dialogRef?.current?.getBoundingClientRect();
      if (rect) {
        const isInDialog =
          rect.top <= e.clientY &&
          e.clientY <= rect.top + rect.height &&
          rect.left <= e.clientX &&
          e.clientX <= rect.left + rect.width;
        if (!isInDialog) {
          handleClose();
        }
      }
    };

    React.useEffect(() => {
      window.addEventListener("mousedown", handleClickOutside);

      return () => {
        window.removeEventListener("mousedown", handleClickOutside);
      };
    });

    let left = desktopWidth - 36; // dekstopWidth - width of pointer - spacing from edge

    if (uiStore.isMobile && elementRect && buttonElement) {
      left = 3 + (elementRect?.left || 0) - (buttonElement.offsetWidth || 0) / 2;
    }

    const pointerPosition = { left };
    const pointerClasses = [styles.pointer];

    if (isFlipped) pointerClasses.push(styles.flipped);

    return (
      <dialog
        className={classes.join(" ")}
        ref={dialogRef}
        style={{ ...position, visibility: dialogVisibility, opacity: dialogOpacity }}
      >
        <button type="button" className={styles.closeButton} onClick={() => handleClose()}>
          <img src={close} alt={tx("aria.closeDialogButton")} />
        </button>
        <div className={styles.inner}>
          <h3 className={styles.title}>{title}</h3>
          <span>{content}</span>
        </div>
        <div className={pointerClasses.join(" ")} style={pointerPosition} />
      </dialog>
    );
  },
);
