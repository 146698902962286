import React, { useEffect } from "react";
import { useStores } from "netbank-shared/src/hooks";
import promiseToPayStyles from "./PromiseToPayPage.scss";
import { tx } from "netbank-shared/src/libs/i18n";
import { formatDate } from "netbank-shared/src/libs/utils";
import { LabelRow } from "~views/shared/LabelRow/LabelRow";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { useDeterminePromiseToPayParamsByAccountType } from "netbank-shared/src/libs/determineLoadingParams";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { generatePromiseToPayForm } from "netbank-shared/src/libs/forms//PromiseToPay/PromiseToPayForm";
import {
  CheckboxFormField,
  FileAttachmentFormField,
  FormWrapper,
  InputFormField,
  TextAreaFormField,
} from "~views/shared/Forms";
import { DatePickerFormField } from "~views/shared/Forms/DatePickerFormField";
import { IPromiseToPayForm } from "netbank-shared/src/libs/forms/PromiseToPay/PromiseToPay";
import { IPromiseToPayProps } from "./PromiseToPayForm";

export const PromiseToPayFIForm = observer(({ data, invoiceInfo, goToAccountUrl, baseAccount }: IPromiseToPayProps) => {
  const { customerStore } = useStores();

  const { creatingPromiseToPay, submitPromiseToPayRequest, cleanUp } = useDeterminePromiseToPayParamsByAccountType(
    baseAccount.accountType,
    baseAccount.accountId,
  );

  const minDate = invoiceInfo.dueDate ? new Date(invoiceInfo.dueDate) : new Date();
  minDate.setDate(minDate.getDate());

  const maxDate = invoiceInfo.dueDate ? new Date(invoiceInfo.dueDate) : new Date();
  maxDate.setDate(maxDate.getDate() + 14);

  const disabledDates = [{ before: minDate, after: maxDate }];

  const defaultValues = {
    phoneNumber: customerStore.currentCustomer?.mobilePhone.value ?? "",
    dueDate: invoiceInfo.dueDate ?? undefined,
    invoiceAmount: invoiceInfo?.invoiceAmount,
    cantPayOnSelectableDates: false,
  };

  const methods = useForm<IPromiseToPayForm>({
    resolver: yupResolver(
      generatePromiseToPayForm({
        promiseToPayDateError: data.dayRequiredText,
        messageError: data.messageRequiredText,
      }),
    ),
    defaultValues,
  });

  const formValues = methods.watch();

  useEffect(() => {
    if (formValues.cantPayOnSelectableDates) {
      methods.setValue("promiseToPayDate", undefined);
    }
  }, [formValues.cantPayOnSelectableDates]);

  useEffect(() => {
    if (formValues.promiseToPayDate) {
      methods.setValue("cantPayOnSelectableDates", false);
    }
  }, [formValues.promiseToPayDate]);

  const paymentInfoRows: { label: string; data?: string }[] = [
    {
      label: data.invoiceLabel || tx("invoice.invoiceNumber"),
      data: invoiceInfo.invoiceNumber,
    },
    {
      label: data.newDueDateLabel || tx("date.newDueDay"),
      data: formatDate(formValues.promiseToPayDate),
    },
  ];

  const navigate = useNavigate();

  const goBackToAccount = () => {
    cleanUp();
    navigate(goToAccountUrl);
  };

  const onSubmitForm: SubmitHandler<IPromiseToPayForm> = async (formData) =>
    submitPromiseToPayRequest(baseAccount.accountId, formData);

  return (
    <FormWrapper
      formMethods={methods}
      onSubmit={onSubmitForm}
      cancelAction={goBackToAccount}
      submitLabel={data.promiseToPayRequestButtonLabel || tx("misc.send")}
      cancelLabel={tx("selfServiceForm.goBack")}
    >
      <div className={promiseToPayStyles.promiseToPayWrapper}>
        <DatePickerFormField
          label={data.dayInputLabel || tx("date.newDueDay")}
          name="promiseToPayDate"
          disabledDays={disabledDates}
          infoPopover={{
            popoverTitle: data.dayInputInfoTitle,
            content: data.dayInputInfoText,
            icon: "question",
          }}
          initialMonth={minDate}
        />
        <div className={promiseToPayStyles.contentWrapper}>
          <InputFormField
            type="tel"
            label={data.phoneInputLabel || tx("misc.phone")}
            name="phoneNumber"
            infoPopover={{
              popoverTitle: data.phoneInputInfoTitle,
              content: data.phoneInputInfoText,
              icon: "question",
            }}
          />
        </div>

        <div className={promiseToPayStyles.contentWrapper}>
          <CheckboxFormField
            label={tx("loan.promiseToPay.cantPayOnSelectedCalenderDays")}
            disabled={creatingPromiseToPay}
            name="cantPayOnSelectableDates"
          />
        </div>
        {formValues.cantPayOnSelectableDates && (
          <>
            <div className={promiseToPayStyles.contentWrapper}>
              <TextAreaFormField
                label={data.messageInputLabel || tx("misc.message")}
                name="message"
                placeholder={data.messageInputPlaceholder || tx("message.textareaPlaceholder")}
                infoPopover={{
                  popoverTitle: data.messageInputInfoTitle,
                  content: data.messageInputInfoText,
                  icon: "question",
                }}
              />
            </div>
            <div className={promiseToPayStyles.contentWrapper}>
              <FileAttachmentFormField
                name="files"
                fileInputId="promiseToPay"
                label={data.fileUploaderLabel || tx("file.desktopLabel")}
                infoPopover={{
                  popoverTitle: data.fileUploaderInfoTitle,
                  content: data.fileUploaderInfoText,
                  icon: "question",
                }}
              />
            </div>
          </>
        )}
        {formValues.promiseToPayDate && (
          <div className={promiseToPayStyles.contentWrapper}>
            <LabelRow label={data.paymentInformationTitle || tx("loan.promiseToPay.paymentInformationTitle")} />
            <div className={promiseToPayStyles.paymentInfo}>
              {paymentInfoRows.map((p, i) => {
                return (
                  <div className={promiseToPayStyles.row} key={`paymentInfoRow-${i}`}>
                    <span className={promiseToPayStyles.label}>{p.label}</span>
                    <span>{p.data}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </FormWrapper>
  );
});
