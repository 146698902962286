/* eslint-disable max-len */
import dayjs from "dayjs";
import {
  ComplianceSigningStatus,
  ComplianceStatus,
  IComplianceSigningStatus,
  IComplianceStatus,
  IUploadAttachmentsResponse,
} from "../../../models/CustomerCompliance/Compliance";

export const mockComplianceStatuses: IComplianceStatus = {
  status: ComplianceStatus.NotStarted,
  complianceDueDate: dayjs().add(3, "day").toDate(),
  completedQuestions: 0,
  outstaningQuestions: 2,
  bookmarkedAnswerId: undefined,
};

export const mockComplianceSignatures: IComplianceSigningStatus = {
  signatureLink: "https://google.com/",
  status: ComplianceSigningStatus.Signable,
};

export const mockUploadAttachments: IUploadAttachmentsResponse = {
  attachments: [{ fileId: "322f4ef7-a62e-46d7-aaa0-fd4f7c195063", fileName: "Test.png" }],
};
